.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: rem($header-height);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: $white;

  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);

  .c-header_logo {
    img {
      height: calc(#{rem($header_height)} - (#{rem($unit-tiny)} * 2));
      width: auto;
    }
  }

  .c-header_burger {
    .c-header_burger_icon {
      width: rem(22px);
      height: rem(16px);
      display: block;
      position: relative;
      span {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $primary;
        left: 0;
        transition: $speed $easing;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: rem(7px);
        }
        &:nth-child(3) {
          top: rem(14px);
        }
      }
    }
    &.is-active {
      .c-header_burger_icon {
        span {
          &:nth-child(1) {
            top: rem(7px);
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            top: rem(7px);
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  @media (min-width: $from-small) {
    
  }
  @media (max-width: $to-small) {
    
  }
}