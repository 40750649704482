.c-heading {
    &.-h1 {
        font-size: rem($font-size-h1);
        font-weight: $extrabold;
        line-height: $font-size-h1 + $font-size-lh;
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h1)} - 15px);
          line-height: $font-size-h1 + $font-size-lh - 15px;
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        font-weight: $extrabold;
        line-height: $font-size-h2 + $font-size-lh;
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h2)} - 10px);
          line-height: $font-size-h2 + $font-size-lh - 10px;
        }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        font-weight: $extrabold;
        line-height: $font-size-h3 + $font-size-lh;
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h3)} - 5px);
        }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
        font-weight: $extrabold;
        font-weight: $bold;
        line-height: $font-size-h4 + $font-size-lh;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h4)} - 4px);
        }
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        font-weight: $bold;
        line-height: $font-size-h5 + $font-size-lh;
    }

    &.-h6 {
        font-size: rem($font-size-h6);
        text-transform: uppercase;
        font-weight: $bold;
        line-height: $font-size-h6 + $font-size-lh;
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
