.b-etapes {
    .b-etapes_item {
        display: grid;
        grid-template-columns: rem(120px) 1fr;
        gap: rem($unit);
        align-items: flex-start;
        .b-etapes_item_step {
            position: relative;
            padding: rem($unit-tiny);
            text-align: center;
            max-width: rem(120px);
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: $primary;
                z-index: -1;
                transform: rotate(9deg);
            }
        }
        &:nth-child(2n) {
            .b-etapes_item_step {
                &::before {
                    transform: rotate(-9deg);
                }
            }
        }
    }
    @media (max-width: $to-small) {
        .b-etapes_item {
            grid-template-columns: 1fr;
            gap: rem($unit-tiny);
        }
    }
}
