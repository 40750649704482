// ==========================================================================
// Base / Fonts
// ==========================================================================

@include font-face(
    $font-lato,
    "fonts/Lato/Lato-Regular"
) {
    font-style:  normal;
    font-weight: 400;
}


@include font-face(
    $font-lato,
    "fonts/Lato/Lato-Bold"
) {
    font-style:  normal;
    font-weight: 700;
}
    
    
@include font-face(
    $font-lato,
    "fonts/Lato/Lato-Black"
) {
    font-style:  normal;
    font-weight: 900;
}