.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second;
}
.u-c-black {
    color: $black;
}
.u-c-white {
    color: $white;
}
.u-c-third {
  color: $third;
}
.u-c-fourth {
  color: $fourth;
}
.u-c-fifth {
  color: $fifth;
}
.u-c-sixth {
  color: $sixth;
}
.u-bg-primary {
  background-color: $primary;
  color: $white!important;
  hr {
    border-color: $white;
  }
}
.u-bg-primary-dark {
  background-color: $primary-dark;
  color: $white!important;
  hr {
    border-color: $white;
  }
}
.u-bg-second {
  background-color: $second;
}
.u-bg-black {
    background-color: $black;
    color: $white!important;
  hr {
    border-color: $white;
  }
}
.u-bg-white {
    background-color: $white;
}
.u-bg-third {
  background-color: $third;
}
.u-bg-fourth {
  background-color: $fourth;
  color: $white!important;
  hr {
    border-color: $white;
  }
}
.u-bg-fifth {
  background-color: $fifth;
}
.u-bg-sixth {
  background-color: $sixth;
}
.u-bg-grey-light {
  background-color: $grey-light;
}