// ==========================================================================
// Settings / Config
// ==========================================================================

// Context
// =============================================================================
// The current stylesheet context. Available values: frontend, editor.
$context: frontend  !default;

// Path is relative to the stylesheets directory.
$assets-path: "../"  !default;

// Typefaces
// =============================================================================
$font-sans-serif: sans-serif;
$font-lato: 'Lato';

// Typography
// =============================================================================
// Base
$font-size-lh: 3px;
$font-size:   16px;
$line-height: 16px + $font-size-lh;
$line-height-default: 1;
$font-family: $font-lato;
$color:       $black;
// Headings
$font-size-h1:  64px  !default;
$font-size-h2:  48px  !default;
$font-size-h3:  32px  !default;
$font-size-h4:  24px  !default;
$font-size-h5:  18px  !default;
$font-size-h6:  14px  !default;
//$line-height-h: $line-height;
$line-height-h: $line-height-default;
// Weights
$light:     300;
$normal:    400;
$medium:    500;
$semibold:  600;
$bold:      700;
$extrabold: 900;

// Transitions
// =============================================================================
$speed:  0.3s;
$easing: cubic-bezier(.46, .46, .3, .99);
$easing2: cubic-bezier(.58, 0, .45, 1);

// Spacing Units
// =============================================================================
$unit:       64px;
$unit-small: 32px;
$unit-tiny:  16px;
$unit-large: 128px;
$unit-tinier: 8px;

// Container
// ==========================================================================
$container-width:           1280px;
$container-large-width:     1440px;
$container-medium-width:    1140px;
$container-small-width:     800px;
$padding:                   $unit;

// Breakpoints
// =============================================================================
$from-tiny:     500px              !default;
$to-tiny:       $from-tiny - 1     !default;
$from-small:    700px              !default;
$to-small:      $from-small - 1    !default;
$from-medium:   1000px             !default;
$to-medium:     $from-medium - 1   !default;
$from-large:    1200px             !default;
$to-large:      $from-large - 1    !default;
$from-big:      1400px             !default;
$to-big:        $from-big - 1      !default;
$from-huge:     1600px             !default;
$to-huge:       $from-huge - 1     !default;
$from-enormous: 1800px             !default;
$to-enormous:   $from-enormous - 1 !default;
$from-gigantic: 2000px             !default;
$to-gigantic:   $from-gigantic - 1 !default;
$from-colossal: 2400px             !default;
$to-colossal:   $from-colossal - 1 !default;


$header-height: 80px !default;
