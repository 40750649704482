.b-relation {
    .b-relation_slider_wrap {
        display: grid;
        grid-template-columns: rem(59px) 1fr;
        gap: rem($unit-small);
        position: relative;
        padding: rem($unit) 0;
        align-items: center;
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            background-color: $fifth;
            height: calc(100% - #{rem($unit)});
            width: 100vw;
            top: 0;
            left: rem(30px);
            border-radius: 8px;
        }
    }
    .b-relation_slider_action {
        transform: translateY(50%);
    }

    .b-relation_slider_content {
        min-width: 100%;
    }
}