.b-cta {
    position: relative;
    z-index: 2;
    .b-cta_wrap {
        border-radius: 8px;
        .o-container {
            padding: 0;
        }
    }
    .b-cta_action {
        transform: translateY(calc(50% + #{rem($unit)}));
        margin-top: rem(-$unit);
    }
    .b-cta_image_wrap {
        border-radius: 8px;
        overflow: hidden;
        max-width: rem(500px);
        margin: 0 auto;
    }
}
