.c-button {
  padding: rem($unit-tiny) 3rem;
  display: inline-flex;
  align-items: center;
  color: $white;
  position: relative;
  font-weight: $extrabold;
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary;
    transition: $speed $easing;
    border-radius: 50%;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid $primary-dark;
    transition: $speed $easing;
    transform: rotate(3deg);
    border-radius: 50%;
  }

  .c-button_label {
    position: relative;
  }

  &.-second {
    &::before {
      background-color: $second;
    }
  }

  @include u-hocus {
    color: $white;
    &::after {
      transform: rotate(-3deg);
    }
    
  }

  &.-disabled {
    cursor: default;
    opacity: 0.5;
  }
  
  &.-prev {
    svg {
      transform: rotate(180deg);
    }
  }
}

.c-button-icon {
  display: inline-flex;
  align-items: center;
  font-weight: $extrabold;
  color: $primary;

  .c-button_icon {
    width: rem(59px);
    height: rem(40px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $second;
      transform: rotate(11deg);
      border-radius: 50%;
      transition: $speed $easing;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $primary;
      transform: rotate(-3deg);
      border-radius: 50%;
      transition: $speed $easing;
    }
  }

  &.-white {
    .c-button_icon {
      &::after {
        border-color: $white;
      }
    }
  }

  svg {
    height: rem(16px);
    width: auto;
    position: relative;
  }

  &.-prev {
    svg {
      transform: rotate(180deg);
    }
  }

  @include u-hocus {
    .c-button_icon {
      &::before {
        transform: rotate(-3deg);
      }
      &::after {
        transform: rotate(11deg);
      }
    }
  }

  &.-disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.c-button-round {
  background-color: $primary;
  color: $white;
  border-radius: 50%;
  width: rem(33px);
  height: rem(33px);
  display: flex;
  align-items: center;
  justify-content: center;

  &.-second {
    background-color: $second;
  }

  &.-white {
    background-color: $white;
    color: $primary;
  }

  svg {
    height: rem($unit-tiny);
  }
}

.c-badge {
  border: 2px solid $primary;
  border-radius: 8px;
  padding: rem($unit-tiny);
  cursor: pointer;
  height: 100%;
  label {
    height: 100%;
  }
  input[type="checkbox"] {
    width: 0;
  }

  @include u-hocus {
    background-color: rgba($primary, 0.16);
  }

  &.is-active {
    background-color: $primary;
    color: $white;
  }
}