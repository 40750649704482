.c-nav {
  background-color: $white;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  height: 0;
  transition: 1s $easing2;
  overflow: hidden;
  z-index: 9;

  .c-nav_wrap {
    height: 100%;
    overflow: auto;
  }
  .c-nav_primary {
    a {
      color: $primary;
      &:hover, &.is-active {
        opacity: 0.5;
      }
    }
  }

  .c-nav_primary_deco {
    position: absolute;
    top: rem($unit);
    left: 0;
    width: 60vw;
    pointer-events: none;
    height: calc(100vh - #{rem($header_height)});
    overflow: hidden;
    svg {
      width: 100%;
    }

    transform: translate(#{rem(-$unit-large)}, #{rem($unit-large)});
    transition: 1s $easing2;
  }
  .c-nav_primary_wrap {

  }

  .anim-text {
    transform: translateY(#{rem($unit-large)});
    transition: 1s $easing2;
  }

}

.has-navOpen {
  .c-nav {
    height: 100vh;
    .anim-text {
      opacity: 1;
      transform: translateY(0);
    }
    .c-nav_primary_deco {
      transform: translate(0, 0);
    }
  }
}
