.b-intro {
    .b-intro_wrap {
        border-radius: 8px;
        overflow: hidden;
    }
}

.b-infos {
    .b-infos_wrap {
        border: 2px solid $primary;
        border-radius: 8px;
    }
    .b-infos_item {
        display: flex;
        align-items: center;
        gap: rem($unit-tinier);
        a {
            text-decoration: underline;
        }
    }
}