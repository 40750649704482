.b-accueil-intro {
    position: relative;
    overflow: hidden;
    background: linear-gradient(transparent, rgba($fifth, 0.15));
    // .b-accueil-intro_deco {
    //     position: absolute;
    //     top: -65%;
    //     left: -3rem;
    //     width: 60vw;
    //     z-index: -1;
    //     svg {
    //         width: 100%;
    //     }
    // }
    .b-accueil-intro_deco {
        position: absolute;
        top: rem($unit-tiny);
        left: rem($unit-small);
        width: rem(460px);
        z-index: -1;
        svg {
            width: 100%;
        }
    }

    .b-accueil-intro_phone {
        margin-left: -0.5rem;
    }

    .b-accueil-intro_image {
        min-height: rem(600px);
        svg {
            position: absolute;
            right: rem(-$unit-small);
            max-width: 100%!important;
            transform: translateX(120%) rotate(25deg);
            transition: 1s $easing;
        }
    }

    @media (max-width: $to-huge) {
        // .b-accueil-intro_deco {
        //     top: -50%;
        // }
    }
    @media (max-width: $to-large) {
        // .b-accueil-intro_deco {
        //     top: -30%;
        // }
    }
    @media (max-width: $to-medium) {
        // .b-accueil-intro_deco {
        //     width: 85%;
        // }
        .b-accueil-intro_image {
            text-align: right;
            transform: translateX(#{rem($unit)});
            min-height: auto;
            svg {
                position: relative;
            }
        }
    }
    
    @media (max-width: $to-small) {
        .b-accueil-intro_deco {
            width: calc(100% - #{rem($unit)});
        }
    }
    // @media (max-width: $to-tiny) {
    //     .b-accueil-intro_deco {
    //         width: 160%;
    //         top: rem(-$unit);
    //     }
    // }

    .b-accueil-intro_deco_courbe {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        pointer-events: none;
        z-index: 2;
    }
}

.is-loaded.is-ready.is-animated {
    .b-accueil-intro_image {
        svg {
            transform: none;
            transition-delay: 1s;
        }
    }
}

.is-loaded.is-ready.is-animated.is-transitioned {
    .b-accueil-intro_image {
        svg {
            transition-delay: 0.6s;
        }
    }
}