.c-accordion_item {
  border: 2px solid $primary;
  border-radius: 8px;
  margin-bottom: rem($unit-tiny);
  transition: $speed $easing;
  &:last-of-type {
    margin-bottom: 0;
  }
  .c-accordion_header {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    display: grid;
    grid-template-columns: 1fr rem(33px);
    gap: rem($unit-tiny);
    padding: rem($unit-tiny) rem($unit-small);
  }
  .c-accordion_plus {
    position: relative;
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    flex-shrink: 0;
    margin-left: 0.66667rem;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      transition: border-color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::before {
      border-top: 2px solid $white;
      top: calc(50% - 1px);
      left: 50%;
      transform: translateX(-50%);
      width: rem(14px);
    }
    &::after {
      border-left: 2px solid $white;
      top: 50%;
      left: calc(50% - 1px);
      transform: translateY(-50%);
      height: rem(14px);
    }
  }
  .c-accordion_main {
    height: 0;
    overflow: hidden;
  }

  .c-accordion_main_wrap {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    .c-accordion_header{
      background-color: $primary;
      color: $white;
    }
  }

  &.is-open {
    .c-accordion_header{
      background-color: $primary;
      color: $white;
    }
    .c-accordion_main {
      height: auto;
      padding: rem($unit-small);
    }
    .c-accordion_main_wrap {
      opacity: 1;
      transform: translateY(0);
    }
    .c-accordion_plus::after {
      transform: scale(0);
    }
  }
}
