.c-card {
  position: relative;
  border-radius: rem(8px);
  height: 100%;
  a {
    color: inherit;
  }

  .c-card_foot {
    margin-top: auto;
    display: grid;
    grid-template-columns: 1fr rem(33px);
    gap: rem($unit-tiny);
    align-items: center;
  }

  &.-page {
    .c-card_wrap {
      padding: rem($unit-small);
      height: 100%;
    }
    .c-card_icon {
      transform: translateY(calc(#{rem(-$unit-small)} - #{rem($unit-tiny)}));
    }

    .c-card_content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  &.-actualite {
    overflow: hidden;
    background-color: $white;
    .c-card_wrap {
      height: 100%;
    }
    .c-card_image {
      height: rem(146px);
      position: relative;
      .c-card_image_wrap {
        overflow: hidden;
        height: 100%;
      }
      img {
        transition: $speed $easing;
      }
    }
    .c-card_date {
      position: absolute;
      right: rem($unit-tiny);
      bottom: 0;
      transform: translateY(50%);
      padding: rem($unit-tiny);
      text-align: center;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
    }
    .c-card_content {
      height: calc(100% - #{rem(146px)});
      display: flex;
      flex-direction: column;
      padding: rem($unit-tiny);
      background-color: rgba($fifth, 0.15);
    }

    &:hover {
      .c-card_image {
        img {
          transform: scale(1.05);
        }
      }
    }

    &.-slider {
      .c-card_content {
        height: calc(100% - #{rem(146px)} - (#{rem($unit-tiny)} * 2));
        background-color: $white;
      }
    }
  }

  &.-ressource {
    .c-card_wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .c-card_head {
      display: grid;
      grid-template-columns: 1fr rem(31px);
      gap: rem($unit-tiny);
    }
  }

  &.-equipe{
    
  }

  &.-social {
    overflow: hidden;
    .c-card_wrap {
      display: flex;
      flex-direction: column;
    }
    .c-card_deco {
      position: absolute;
      right: rem(-$unit-small);
      top: 50%;
      transform: translateY(-50%);
      color: $primary;
      svg {
        width: 200px;
        stroke: $fifth;
        stroke-width: 0.1px;
        transform: rotate(20deg);
      }
    }
  }
}
