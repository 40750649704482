.c-breadcrumb {
    font-size: rem($font-size-h6);
    text-transform: uppercase;
    font-weight: $bold;
    a {
        color: $primary;
    }
    span {
        &:last-of-type {
            color: $grey;
        }
    }
}