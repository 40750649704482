.c-text{
    a:not(.c-button) {
        color: $second;
        text-decoration: underline;
    }
    ul {
        padding-left: 0;
        li {
            list-style-type: none;
            position: relative;
            padding-left: calc(#{rem(18px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &:last-of-type {
                margin-bottom: 0;
            }
            &::before {
                content: " ";
                position: absolute;
                left: 0;
                width: rem(12px);
                height: rem(12px);
                background-color: $primary;
                border-radius: 50%;
                top: 3px;
            }
        }
    }
    ol {
        counter-reset: ol_counter; /* on initialise et nomme un compteur */
        padding: 0;
        li {
            padding: 0;
            list-style-type: none;
            counter-increment: ol_counter; /* on incrémente le compteur à chaque nouveau li */
            position: relative;
            padding-left: calc(#{rem(18px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &::before {
                content: counter(ol_counter)"."; /* on affiche le compteur */
                width: rem(16px);
                height: rem(16px);
                position: absolute;
                font-weight: $extrabold;
                left: 0;
            }
        }
    }
    img {
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
    }
}

blockquote {
    color: $second;
    font-weight: $extrabold;
    font-size: rem($font-size-h3);
    line-height: $font-size-h3 + $font-size-lh;
    margin: 0;
}
