.b-video {

    .b-video_wrap {
        position: relative;
        border-radius: rem(5px);
        overflow: hidden;
        box-shadow: 0 rem(10px) rem(30px) rgba($black, 0.2);
    }
    .b-video_player_wrap {
        position: absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: $primary;
            opacity: 0.19;
            z-index: 3;
        }
        .b-video_player {
            position: relative;
            z-index: 4;
        }
        .o-ratio_content_bg {
            transition: $speed $easing;
        }
        .c-button_icon {
            transition: $speed $easing;
            transform-origin: center;
        }
        &:hover {
            .o-ratio_content_bg {
                transform: scale(1.05);
            }
            .c-button_icon {
                transform: rotate(120deg);
            }
        }
    }
}