// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #ffffff;
$black: #1e4093;

$primary: #1e4093;
$primary-dark: #0a2e84;
$second: #f39149;
$third: #fec700;
$fourth: #d52a6f;
$fifth: #5ec8f0;
$sixth: #7eedc2;

$green: #77c483;
$red: #d1603e;

$grey: rgba($primary, 0.5);
$grey-light: rgba($fifth, 0.15);

$black-overlay-light: rgba(0, 0, 0, 0.5);


// Specific
// =============================================================================
// Link
$link-color:       $black;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color:       $primary;
$selection-background-color: $second;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
