.c-footer{
    .c-footer_logo {
        display: flex;
        background-color: $white;
        padding: rem($unit-small);
        border-radius: 10px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        img {
            max-width: rem(200px);
        }
    }    

    @media (max-width: $to-huge) {
        .u-text-right {
            text-align: left!important;
        }
    }
}
