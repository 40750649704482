.c-slider{
    &.-slide-hidden{
        overflow: hidden;
    }
}

.c-slider_container{
    overflow: hidden;
}

.swiper-slide {
    height: auto!important;
}
