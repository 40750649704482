.c-search {
    background-color: $white;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    height: 0;
    z-index: 11;
    transition: 0.5s $easing2;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .anim-text {
        transform: translateY(#{rem($unit-large)});
        transition: 0.5s $easing2;
    }
}

.has-searchOpen {
    .c-search {
        height: #{rem(146px)};
    }
    .anim-text {
        opacity: 1;
        transform: translateY(0);
    }
}

.c-search_result {
    .c-search_nav {
        border-radius: 8px;
        .c-search_nav_body {
            .c-search_nav_body_item {
                padding: rem($unit-tinier) 0;
                display: grid;
                grid-template-columns: 1fr rem(40px);
                grid-gap: rem($unit-tiny);
                border-bottom: 1px solid $grey-light;
                font-weight: $medium;
                color: $black;
                @include u-hocus {
                    color: $second;
                }
            }
        }
    }
}

